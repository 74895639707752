














import {Component, Vue, Watch} from 'vue-property-decorator';
import {vxm} from '@/store';
import LoaderBottom from '@/components/LoaderBottom.vue';
import PeopleInterface from '@/types/PeopleInterface';

@Component({
  components: {LoaderBottom},
})
export default class People extends Vue {
  people = [] as PeopleInterface[];
  loading = true;

  created() {
    this.peopleSearch();
  }
  @Watch('$route.params.token')
  doSearch() {
    this.peopleSearch();
  }
  peopleSearch() {
    const searchToken = this.$route.params.token;
    if (searchToken) {
      vxm.search
        .doSearch({search: searchToken, type: 'people', page: 1, limit: 20})
        .then((res: any) => {
          const data = res.data.data;
          this.people = data.users && data.users.length ? data.users : [];
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  getAvatar(index: number) {
    return this.people[index].avatar || require('@/assets/icons/avatar-default.svg');
  }
}
